import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const testimonials = [
  {
    name: "Dr. Sarah Johnson",
    role: "Clinical Psychologist",
    comment: "TRIBE is revolutionizing how we approach mental health in educational settings. It's a much-needed platform that bridges the gap between students and mental health resources."
  },
  {
    name: "Prof. Michael Lee",
    role: "Education Technology Researcher",
    comment: "The integration of AI and community support in TRIBE is impressive. It has the potential to make a significant impact on student well-being across campuses."
  },
  {
    name: "Dr. Emily Chen",
    role: "Adolescent Psychiatrist",
    comment: "TRIBE's approach to destigmatizing mental health issues among young people is commendable. It's creating a safe space that's both accessible and engaging for students."
  }
];

export default function TestimonialsCarousel() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <section className="py-16 p-4 bg-white">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-8 text-center text-[#01032c]">What Professionals Say</h2>
        <div className="relative w-3/4 mx-auto">
          <div className="bg-[#86C2F1] p-8 rounded-lg shadow-lg">
            <p className="text-lg mb-4 text-[#171A58]">"{testimonials[currentIndex].comment}"</p>
            <div className="flex items-center">
              <div>
                <p className="font-semibold text-[#2d3254]">{testimonials[currentIndex].name}</p>
                <p className="text-sm text-[#171A58]">{testimonials[currentIndex].role}</p>
              </div>
            </div>
          </div>
          <button 
            onClick={prevTestimonial} 
            className="absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-12 bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition duration-300"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
          <button 
            onClick={nextTestimonial} 
            className="absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-12 bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 transition duration-300"
          >
            <ChevronRight className="w-6 h-6" />
          </button>
        </div>
      </div>
    </section>
  );
}

