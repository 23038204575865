import React from 'react';
import { Users, BookOpen, Megaphone, Shield } from 'lucide-react';

export default function WhyStudentsLove() {
  return (
    <section className="py-16 p-4 bg-white text-black">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-8 text-center text-[#01032c]">WHY STUDENTS LOVE TRIBE</h2>
        <p className="text-xl text-center mb-12 text-[#171A58]">
          TRIBE exists to ensure no young person feels alone in their struggles. It bridges the gap between mental health resources and students, creating a network that nurtures well-being and inspires growth.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="bg-[#86C2F1] p-6 rounded-lg shadow-md border border-blue-200 hover:border-red-500 transition-colors">
            <Users className="w-12 h-12 text-blue-500 mb-4" />
            <h3 className="text-xl font-semibold mb-4 text-[#171A58]">Community</h3>
            <p className="text-[#2d3254]">Connect with psychologists and peers for one-on-one or group interactions.</p>
          </div>
          <div className="bg-[#86C2F1] p-6 rounded-lg shadow-md border border-blue-200 hover:border-red-500 transition-colors">
            <BookOpen className="w-12 h-12 text-blue-500 mb-4" />
            <h3 className="text-xl font-semibold mb-4 text-[#171A58]">Workshops</h3>
            <p className="text-[#2d3254]">Engage in curated workshops on stress management, self-awareness, and mental well-being.</p>
          </div>
          <div className="bg-[#86C2F1] p-6 rounded-lg shadow-md border border-blue-200 hover:border-red-500 transition-colors">
            <Megaphone className="w-12 h-12 text-blue-500 mb-4" />
            <h3 className="text-xl font-semibold mb-4 text-[#171A58]">Campaigns</h3>
            <p className="text-[#2d3254]">Drive impactful programs to destigmatize mental health issues and promote early intervention.</p>
          </div>
          <div className="bg-[#86C2F1] p-6 rounded-lg shadow-md border border-blue-200 hover:border-red-500 transition-colors">
            <Shield className="w-12 h-12 text-blue-500 mb-4" />
            <h3 className="text-xl font-semibold mb-4 text-[#171A58]">Safe Space</h3>
            <p className="text-[#2d3254]">Create a confidential and empathetic platform for sharing and healing.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

