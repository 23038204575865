import React from 'react';
import { TrendingUp, DollarSign } from 'lucide-react';

export default function MarketDemand() {
  return (
    <section className="py-16 p-4 bg-white border-y border-blue-500">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-8 text-center text-black">Growing Demand for Mental Health Support</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <ul className="space-y-4 text-black">
              <li className="flex items-center">
                <TrendingUp className="w-6 h-6 text-red-500 mr-2" />
                <span className="text-black">50% of internet users aged 13–24 search for self-help or mental health resources.</span>
              </li>
              <li className="flex items-center">
                <TrendingUp className="w-6 h-6 text-red-500 mr-2" />
                <span className="text-black">Mental health app usage among youth has grown by 150% in the last 3 years.</span>
              </li>
              <li className="flex items-center">
                <TrendingUp className="w-6 h-6 text-red-500 mr-2" />
                <span className="text-black">In India alone, 400M+ youth represent an untapped opportunity for mental health solutions.</span>
              </li>
            </ul>
          </div>
          <div className="bg-[#86C2F1] text-black p-8 rounded-lg shadow-md text-center border border-blue-200 hover:border-red-500 transition-colors">
            <h3 className="text-2xl font-semibold mb-4 text-[#171A58]">Mental Health App Market by 2030</h3>
            <div className="flex items-center justify-center">
              <DollarSign className="w-12 h-12 text-[#01032c] mr-2" />
              <p className="text-5xl font-bold text-[#01032c]">17.5 Billion</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

