import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Problem from './components/Problem';
import Solution from './components/Solution';
import About from './components/About';
import Tools from './components/Tools';
import WhyStudentsLove from './components/WhyStudentsLove';
import MarketDemand from './components/MarketDemand';
import EventsAndBooking from './components/EventsAndBooking';
import TestimonialsCarousel from './components/TestimonialsCarousel';

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <Hero />
        <Problem />
        <Solution />
        <About />
        <Tools />
        <WhyStudentsLove />
        <MarketDemand />
        <EventsAndBooking />
        <TestimonialsCarousel />
      </main>
      <Footer />
    </div>
  );
}

export default App;

