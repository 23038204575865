import React from 'react';
import { Shield, BookOpen, Users, Megaphone } from 'lucide-react';

export default function Solution() {
  return (
    <section className="py-16 p-4 bg-white text-black border-y border-blue-500">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-8 text-center text-[#01032c]">Solution</h2>
        <h3 className="text-2xl font-semibold mb-8 text-center text-[#01032c]">A Holistic Mental Health Tool</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-[#86C2F1] p-6 rounded-lg shadow-md border border-blue-200 hover:border-red-500 transition-colors">
            <div className="flex items-center mb-4">
              <Shield className="w-8 h-8 text-blue-500 mr-2" />
              <h4 className="text-xl font-semibold text-[#171A58]">SOLUTION 01</h4>
            </div>
            <p className="text-[#2d3254]">Safe Spaces: Confidential, empathetic platforms for sharing.</p>
          </div>
          <div className="bg-[#86C2F1] p-6 rounded-lg shadow-md border border-blue-200 hover:border-red-500 transition-colors">
            <div className="flex items-center mb-4">
              <BookOpen className="w-8 h-8 text-blue-500 mr-2" />
              <h4 className="text-xl font-semibold text-[#171A58]">SOLUTION 02</h4>
            </div>
            <p className="text-[#2d3254]">Workshops: Focused sessions on stress, self-awareness, and resilience.</p>
          </div>
          <div className="bg-[#86C2F1] p-6 rounded-lg shadow-md border border-blue-200 hover:border-red-500 transition-colors">
            <div className="flex items-center mb-4">
              <Users className="w-8 h-8 text-blue-500 mr-2" />
              <h4 className="text-xl font-semibold text-[#171A58]">SOLUTION 03</h4>
            </div>
            <p className="text-[#2d3254]">Community: Connect with peers and professionals for support.</p>
          </div>
          <div className="bg-[#86C2F1] p-6 rounded-lg shadow-md border border-blue-200 hover:border-red-500 transition-colors">
            <div className="flex items-center mb-4">
              <Megaphone className="w-8 h-8 text-blue-500 mr-2" />
              <h4 className="text-xl font-semibold text-[#171A58]">SOLUTION 04</h4>
            </div>
            <p className="text-[#2d3254]">Campaigns: Destigmatizing mental health with engaging programs.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

