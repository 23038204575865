import React from 'react';

export default function About() {
  return (
    <section id="about" className="py-16 p-4 bg-gray-100">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-8 text-center text-[#01032c]">Gurucool's TRIBE</h2>
        <p className="text-xl text-center max-w-3xl mx-auto text-[#171A58]">
          TRIBE is a holistic mental health tool dedicated to fostering a supportive community for students and young people. It connects individuals with trained psychologists, peers, and curated resources to promote emotional well-being, build resilience, and empower young minds to navigate life's challenges confidently.
        </p>
      </div>
    </section>
  );
}

