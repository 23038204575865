import React from 'react';
import { Facebook, Twitter, Instagram, Linkedin, Mail, Globe, Phone } from 'lucide-react';

export default function Footer() {
  return (
    <footer id="contact" className="bg-gray-100 text-black py-16 border-t border-blue-500">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-start space-y-8 md:space-y-0 md:space-x-8">
          <div className="w-full md:w-auto text-center md:text-left">
            <h2 className="text-3xl font-bold mb-4 text-[#01032c]">Gurucool TRIBE</h2>
            <p className="mb-4 text-[#171A58]">Your Mental Health Ally</p>
            <div className="flex justify-center md:justify-start space-x-4">
              <Facebook className="w-6 h-6 text-blue-500 hover:text-red-500 cursor-pointer transition-colors" />
              <Twitter className="w-6 h-6 text-blue-500 hover:text-red-500 cursor-pointer transition-colors" />
              <Instagram className="w-6 h-6 text-blue-500 hover:text-red-500 cursor-pointer transition-colors" />
              <Linkedin className="w-6 h-6 text-blue-500 hover:text-red-500 cursor-pointer transition-colors" />
            </div>
          </div>
          <div className="w-full md:w-auto text-center md:text-left">
            <h3 className="text-xl font-semibold mb-4 text-[#171A58]">Contact Us</h3>
            <div className="flex flex-col items-center md:items-start space-y-2">
              <div className="flex items-center">
                <Mail className="w-5 h-5 text-blue-500 mr-2" />
                <p className="text-[#2d3254]">contact@gurucool.xyz</p>
              </div>
              <div className="flex items-center">
                <Globe className="w-5 h-5 text-blue-500 mr-2" />
                <p className="text-[#2d3254]">tribe.gurucool.xyz</p>
              </div>
              <div className="flex items-center">
                <Phone className="w-5 h-5 text-blue-500 mr-2" />
                <p className="text-[#2d3254]">+91 9119145922, +91 9560369013</p>
              </div>
            </div>
          </div>
          <div className="w-full md:w-auto text-center md:text-left">
            <h3 className="text-xl font-semibold mb-4 text-[#171A58]">Our Mission</h3>
            <p className="text-sm text-[#2d3254]">
              To create a world where every student feels heard, supported, and empowered to thrive emotionally, mentally, and academically.
            </p>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-blue-200 text-center">
          <p className="text-[#2d3254]">&copy; 2023 Gurucool TRIBE. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

