import React from 'react';
import { ArrowRight } from 'lucide-react';

export default function Hero() {
  return (
    <section className="bg-white text-[#01032c] py-20 border-b p-4 border-blue-500">
      <div className="container mx-auto text-center">
        <h1 className="text-5xl font-bold mb-4 text-[#01032c]">Gurucool TRIBE</h1>
        <p className="text-2xl mb-8 text-[#01032c]">Your Mental Health Ally</p>
        <p className="text-xl mb-8 text-[#01032c]">Powered by Gurucool</p>
        <h2 className="text-3xl font-semibold mb-8 text-[#01032c]">Young Minds Under Pressure</h2>
        <button className="bg-black text-white px-8 py-3 rounded-full text-xl font-semibold hover:bg-red-500 transition duration-300 flex items-center justify-center mx-auto">
          Continue
          <ArrowRight className="ml-2 w-5 h-5" />
        </button>
      </div>
    </section>
  );
}

