import React, { useState, useEffect } from 'react';
import { Menu, X, User, Search } from 'lucide-react';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
      if (window.innerWidth >= 1024) {
        setIsMenuOpen(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-white text-black p-4 border-b border-blue-500">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            {isMobile && (
              <button
                onClick={toggleMenu}
                className="text-blue-500 hover:text-red-500 transition-colors"
              >
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            )}
            <img src="/gurucool-logo.svg" alt="Gurucool Logo" className="w-36 h-12 invert" />
          </div>
          <nav className={`${isMobile ? 'hidden' : 'block'}`}>
            <ul className="flex space-x-6">
              <li><a href="#about" className="hover:text-red-500 transition-colors">About</a></li>
              <li><a href="#tools" className="hover:text-red-500 transition-colors">Tools</a></li>
              <li><a href="#team" className="hover:text-red-500 transition-colors">Team</a></li>
              <li><a href="#contact" className="hover:text-red-500 transition-colors">Contact</a></li>
            </ul>
          </nav>
          <div className="flex items-center space-x-4">
            <Search className="w-6 h-6 text-blue-500 hover:text-red-500 cursor-pointer transition-colors" />
            <User className="w-6 h-6 text-blue-500 hover:text-red-500 cursor-pointer transition-colors" />
          </div>
        </div>
      </div>
      {isMobile && isMenuOpen && (
        <div className="fixed inset-0 bg-white z-50 pt-20">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <button
              onClick={toggleMenu}
              className="absolute top-4 right-4 text-blue-500 hover:text-red-500 transition-colors"
            >
              <X size={24} />
            </button>
            <nav>
              <ul className="flex flex-col space-y-4">
                <li><a href="#about" className="text-2xl hover:text-red-500 transition-colors" onClick={toggleMenu}>About</a></li>
                <li><a href="#tools" className="text-2xl hover:text-red-500 transition-colors" onClick={toggleMenu}>Tools</a></li>
                <li><a href="#team" className="text-2xl hover:text-red-500 transition-colors" onClick={toggleMenu}>Team</a></li>
                <li><a href="#contact" className="text-2xl hover:text-red-500 transition-colors" onClick={toggleMenu}>Contact</a></li>
              </ul>
            </nav>
          </div>
        </div>
      )}
    </header>
  );
}

