import React from "react";
import {
  Book,
  Lightbulb,
  Award,
  FileText,
  ArrowUp,
  Film,
  PenTool,
  HelpCircle,
  Calendar,
  BookOpen,
  Library,
  Rss,
} from "lucide-react";

export default function Tools() {
  const tools = [
    { name: "Tuition", icon: Book },
    { name: "Classes", icon: Lightbulb },
    { name: "Scholarship", icon: Award },
    { name: "Flashcards", icon: FileText },
    { name: "Level Up", icon: ArrowUp },
    { name: "Courseflix", icon: Film },
    { name: "Padhaai", icon: PenTool },
    { name: "Quizzes", icon: HelpCircle },
    { name: "Ask", icon: HelpCircle },
    { name: "Events", icon: Calendar },
    { name: "Study Notes", icon: BookOpen },
    { name: "Library", icon: Library },
    { name: "Feed", icon: Rss },
  ];

  return (
    <section id="tools" className="py-16 p-4" style={{ backgroundColor: "#01032c" }}>
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-8 text-center text-white">
          OTHER TOOLS FROM THE SUITE
        </h2>
        <div className="flex flex-wrap justify-center gap-4">
          {tools.map((tool, index) => {
            const Icon = tool.icon;
            return (
              <div
                key={index}
                className="bg-transparent p-4 rounded-lg shadow-md text-center border border-blue-200 hover:border-red-500 transition-colors flex flex-col items-center"
                
              >
                <Icon className="w-10 h-10 text-white mb-2" />
                <span className="text-white font-semibold">{tool.name}</span>
              </div>
            );
          })}
        </div>
        <div className="mt-8 text-center">
          <p className="text-lg font-semibold text-white">Tools are gamified</p>
          <p className="text-lg font-semibold text-white">Tools are AI-powered</p>
        </div>
      </div>
    </section>
  );
}
