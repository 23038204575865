import React from 'react';
import { AlertTriangle, Brain, Users } from 'lucide-react';

export default function Problem() {
  return (
    <section className="py-16 p-4 bg-gray-100 bg-white">
      <div className="container mx-auto">
        <h2 className="text-2xl font-bold p-1 mb-8 text-center text-[#01032c]">Problem</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-[#86C2F1] p-6 rounded-lg shadow-md border border-blue-200 hover:border-red-500 transition-colors">
            <div className="flex items-center mb-4">
              <AlertTriangle className="w-8 h-8 text-red-500 mr-2" />
              <h3 className="text-xl text-[#171A58] font-semibold">01</h3>
            </div>
            <p className="text-[#2d3254]">70% of young people with mental health challenges don't seek help.</p>
          </div>
          <div className="bg-[#86C2F1] p-6 rounded-lg shadow-md border border-blue-200 hover:border-red-500 transition-colors">
            <div className="flex items-center mb-4">
              <Brain className="w-8 h-8 text-red-500 mr-2" />
              <h3 className="text-xl text-[#171A58] font-semibold">02</h3>
            </div>
            <p className="text-[#2d3254]">Stress, anxiety, and depression affect over 25% of students globally.</p>
          </div>
          <div className="bg-[#86C2F1] p-6 rounded-lg shadow-md border border-blue-200 hover:border-red-500 transition-colors">
            <div className="flex items-center mb-4">
              <Users className="w-8 h-8 text-red-500 mr-2" />
              <h3 className="text-xl text-[#171A58] font-semibold">03</h3>
            </div>
            <p className="text-[#2d3254]">Academic pressure, peer expectations, and social media stress.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

