import React, { useState } from "react";
import { Calendar, BookOpen, Users, X } from "lucide-react";

export default function EventsAndBooking() {
  const [showModal, setShowModal] = useState(false);

  const events = [
    { title: "Mental Health Workshop", date: "2023-06-15", type: "Workshop" },
    { title: "Stress Management Seminar", date: "2023-06-22", type: "Seminar" },
    { title: "Group Therapy Session", date: "2023-06-29", type: "Therapy" },
  ];

  const toggleModal = () => setShowModal(!showModal);

  return (
    <section className="py-16 bg-white p-4">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-8 text-center" style={{ color: "#01032c" }}>
          Upcoming Events & Sessions
        </h2>
        <div className="flex flex-wrap justify-center">
          <div className="w-full md:w-1/2 p-4">
            <h3 className="text-2xl font-semibold mb-4 text-center" style={{ color: "#171A58" }}>
              Events Calendar
            </h3>
            <ul className="space-y-4">
              {events.map((event, index) => (
                <li key={index} className="flex items-center p-4 rounded-lg" style={{ backgroundColor: "#86C2F1" }}>
                  <Calendar className="w-6 h-6 text-blue-900 mr-3" />
                  <div>
                    <h4 className="font-semibold" style={{ color: "#171A58" }}>{event.title}</h4>
                    <p className="text-sm" style={{ color: "#2d3254" }}>
                      {event.date} - {event.type}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full md:w-1/2 p-4">
            <h3 className="text-2xl font-semibold mb-4 text-center" style={{ color: "#171A58" }}>
              Book a Session
            </h3>
            <div className="p-6 rounded-lg" style={{ backgroundColor: "#86C2F1" }}>
              <p className="mb-4" style={{ color: "#171A58" }}>
                Connect with our mental health professionals for personalized support.
              </p>
              <div className="space-y-4">
                <button
                  onClick={toggleModal}
                  className="flex items-center justify-center w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
                >
                  <BookOpen className="w-5 h-5 mr-2" />
                  Book Individual Session
                </button>
                <button
                  onClick={toggleModal}
                  className="flex items-center justify-center w-full bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition duration-300"
                >
                  <Users className="w-5 h-5 mr-2" />
                  Join Group Session
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-96">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold" style={{ color: "#171A58" }}>
                Book Event
              </h3>
              <button onClick={toggleModal} className="text-gray-500 hover:text-gray-800">
                <X className="w-5 h-5" />
              </button>
            </div>
            <form>
              <div className="mb-4">
                <label className="block text-sm font-medium" style={{ color: "#2d3254" }}>
                  Name
                </label>
                <input
                  type="text"
                  className="w-full border border-gray-300 rounded px-3 py-2"
                  placeholder="Your Name"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium" style={{ color: "#2d3254" }}>
                  Email
                </label>
                <input
                  type="email"
                  className="w-full border border-gray-300 rounded px-3 py-2"
                  placeholder="Your Email"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium" style={{ color: "#2d3254" }}>
                  Contact Number
                </label>
                <input
                  type="tel"
                  className="w-full border border-gray-300 rounded px-3 py-2"
                  placeholder="Your Contact Number"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </section>
  );
}
